import React from "react"
import { Text, Input } from "app/wellzesta-ui"
import { useField } from "formik"
import clsx from "clsx"

const FormField = React.memo(function FormField({
  label,
  name,
  validate,
  className,
  component: Component = Input,
  ...props
}) {
  const [field, meta] = useField({ name, ...props })
  const errorMessage = meta.touched && meta.error

  return (
    <div className="mb-5">
      {label && (
        <Text size="sm" htmlFor={name} as="label" className="mb-2 text-left">
          {label}
        </Text>
      )}
      <Component
        id={name}
        {...field}
        {...props}
        className={clsx(
          errorMessage &&
            "border-2 border-red-100 text-red-100 focus:outline-none",
          className, "font-semibold"
        )}
      />
      <Text
        size="sm"
        hidden={!errorMessage}
        className="block absolute text-red-100 mt-1"
        as="span"
        style={{ lineHeight: 1 }}
      >
        {errorMessage}
      </Text>
    </div>
  )
})

export default FormField
